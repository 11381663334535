
import _1 from '../assets/img/gallery/1.jpg';
import _2 from '../assets/img/gallery/2.jpg';
import _3 from '../assets/img/gallery/3.jpg';
import _4 from '../assets/img/gallery/4.jpg';
import _5 from '../assets/img/gallery/5.jpg';
import _6 from '../assets/img/gallery/6.jpg';
import _7 from '../assets/img/gallery/7.jpg';
import _8 from '../assets/img/gallery/8.jpg';
import _9 from '../assets/img/gallery/9.jpg';
import _10 from '../assets/img/gallery/10.jpg';
import _11 from '../assets/img/gallery/11.jpg';
import _12 from '../assets/img/gallery/12.jpg';
import _13 from '../assets/img/gallery/13.jpg';
import _14 from '../assets/img/gallery/14.jpg';
import _15 from '../assets/img/gallery/15.jpg';
import _16 from '../assets/img/gallery/16.jpg';
import _17 from '../assets/img/gallery/17.jpg';


export default [
    {
        image: _1,
        name: {
            pt_br: "Imagem 1",
            en_us: "Imagem 1",
            es_es: "Imagem 1"
        }
    }, {
        image: _2,
        name: {
            pt_br: "Imagem 2",
            en_us: "Imagem 2",
            es_es: "Imagem 2"
        }
    }, {
        image: _3,
        name: {
            pt_br: "Imagem 3",
            en_us: "Imagem 3",
            es_es: "Imagem 3"
        }
    }, {
        image: _4,
        name: {
            pt_br: "Imagem 4",
            en_us: "Imagem 4",
            es_es: "Imagem 4"
        }
    }, {
        image: _5,
        name: {
            pt_br: "Imagem 5",
            en_us: "Imagem 5",
            es_es: "Imagem 5"
        }
    }, {
        image: _6,
        name: {
            pt_br: "Imagem 6",
            en_us: "Imagem 6",
            es_es: "Imagem 6"
        }
    }, {
        image: _7,
        name: {
            pt_br: "Imagem 7",
            en_us: "Imagem 7",
            es_es: "Imagem 7"
        }
    }, {
        image: _8,
        name: {
            pt_br: "Imagem 8",
            en_us: "Imagem 8",
            es_es: "Imagem 8"
        }
    }, {
        image: _9,
        name: {
            pt_br: "Imagem 9",
            en_us: "Imagem 9",
            es_es: "Imagem 9"
        }
    }, {
        image: _10,
        name: {
            pt_br: "Imagem 10",
            en_us: "Imagem 10",
            es_es: "Imagem 10"
        }
    }, {
        image: _11,
        name: {
            pt_br: "Imagem 11",
            en_us: "Imagem 11",
            es_es: "Imagem 11"
        }
    }, {
        image: _12,
        name: {
            pt_br: "Imagem 12",
            en_us: "Imagem 12",
            es_es: "Imagem 12"
        }
    }, {
        image: _13,
        name: {
            pt_br: "Imagem 13",
            en_us: "Imagem 13",
            es_es: "Imagem 13"
        }
    }, {
        image: _14,
        name: {
            pt_br: "Imagem 14",
            en_us: "Imagem 14",
            es_es: "Imagem 14"
        }
    }, {
        image: _15,
        name: {
            pt_br: "Imagem 15",
            en_us: "Imagem 15",
            es_es: "Imagem 15"
        }
    }, {
        image: _16,
        name: {
            pt_br: "Imagem 16",
            en_us: "Imagem 16",
            es_es: "Imagem 16"
        }
    }, {
        image: _17,
        name: {
            pt_br: "Imagem 17",
            en_us: "Imagem 17",
            es_es: "Imagem 17"
        }
    }
];