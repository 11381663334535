
import './style.css';

import Translations from '../../data/Translations';
import BedroomsArray from '../../data/Bedrooms';
import { useState } from 'react';
import Slider from '../../components/Slider/Slider';
import LazyImage from '../../components/LazyImage/LazyImage';

const Bedrooms = ({ currentLanguage }) => {
    const [activedSlider, setActivedSlider] = useState(false);
    const [sliderIndex, setSliderIndex] = useState(3);

    const handleSliderChange = (actived, index = 0) => {
        setActivedSlider(actived);

        if (index < 0) {
            index = BedroomsArray.length - 1;
        } else if (index > BedroomsArray.length - 1) {
            index = 0;
        }

        setSliderIndex(index);
    }

    return (
        <>
            <section id="bedrooms" class="bedrooms section-bg">
                <div class="container" data-aos="fade-up">

                    <div class="section-title">
                        <h2>{Translations.accommodations[currentLanguage]}</h2>
                        <h3>{Translations.comfortAndConvenienceForYou[currentLanguage]}</h3>
                        <p>{Translations.comfortAndConvenienceForYouDescription[currentLanguage]}</p>
                    </div>

                    <div class="row row-horizontal-scroll bedrooms-container" data-aos="fade-up" data-aos-delay="200">
                        {
                            BedroomsArray.map((item, index) => {
                                return (
                                    <div class="col-lg-4 col-md-6 bedrooms-item" key={index} onClick={_ => handleSliderChange(true, index)}>
                                        <LazyImage src={item.image} />
                                        {/* <div class="bedrooms-info">
                                            <h5>{item.name[currentLanguage]}</h5>
                                        </div> */}
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </section>

            <Slider actived={activedSlider} activeIndex={sliderIndex} imagesArray={BedroomsArray} currentLanguage={currentLanguage} handleSliderChange={handleSliderChange} />
        </>
    )
}

export default Bedrooms;