
export default {
    selectLanguage: {
        pt_br: "Selecione um idioma",
        en_us: "Select a language",
        es_es: "Selecciona un idioma"
    },
    phone: {
        pt_br: "Telefone",
        en_us: "Phone",
        es_es: "Teléfono"
    },
    home: {
        pt_br: "Início",
        en_us: "Home",
        es_es: "Inicio"
    },
    services: {
        pt_br: "Serviços",
        en_us: "Services",
        es_es: "Servicios"
    },
    gallery: {
        pt_br: "Galeria",
        en_us: "Gallery",
        es_es: "Galería"
    },
    about: {
        pt_br: "Sobre",
        en_us: "About",
        es_es: "Sobre"
    },
    events: {
        pt_br: "Eventos",
        en_us: "Events",
        es_es: "Eventos"
    },
    accommodations: {
        pt_br: "Acomodações",
        en_us: "Accommodations",
        es_es: "Alojamientos"
    },
    accommodationsDescription: {
        pt_br: "Temos 75 apartamentos e 5 suítes, onde cada uma conta com uma infraestrutura ideal para seu conforto e comodidade",
        en_us: "We have 75 apartments and 5 suites, where each onde has an infrastructure for your comfort and convenience",
        es_es: "Contamos con 75 apartamentos y 5 suites, cada uno con una infraestructura ideal para su comodidad y conveniencia"
    },
    eventHalls: {
        pt_br: "Salões de Eventos",
        en_us: "Event Halls",
        es_es: "Salones de Eventos"
    },
    eventHallsDescription: {
        pt_br: "Contamos com salões para eventos com capacidade de 25, 60 e 100 pessoas",
        en_us: "We have rooms for events with capacity of 25, 60 and 100 people",
        es_es: "Contamos con salones de eventos con capacidad de 25, 60 y 100 personas"
    },
    _24hourService: {
        pt_br: "Atendimento 24 horas",
        en_us: "24 hour service",
        es_es: "Servicio 24 horas"
    },
    _24hourServiceDescription: {
        pt_br: "Atendimento disponível a qualquer horário do dia ou da noite",
        en_us: "Service available at any time of day or night",
        es_es: "Servicio disponible a cualquier hora del día o de la noche."
    },
    accessibility: {
        pt_br: "Acessibilidade",
        en_us: "Accessibility",
        es_es: "Accesibilidad"
    },
    accessibilityDescription: {
        pt_br: "Possuímos ambientes acessíveis por todos nossos clientes, conforme seguem as leis de acessibilidade",
        en_us: "We have accessible environments for all our customers, as per the accessibility laws",
        es_es: "Contamos con entornos accesibles para todos nuestros clientes, según las leyes de accesibilidad."
    },
    internalLinks: {
        pt_br: "Links Internos",
        en_us: "Internal Links",
        es_es: "Vínculos Internos"
    },
    ourSocialNetworks: {
        pt_br: "Nossas mídias sociais",
        en_us: "Our social networks",
        es_es: "Nuestras redes sociales"
    },
    ourSocialNetworksDescription: {
        pt_br: "Entre em contato conosco através de uma de nossas mídias sociais",
        en_us: "Get in touch with us through one of our social media",
        es_es: "Póngase en contacto con nosotros mediante nuestras redes sociales."
    },
    copyright: {
        pt_br: "© Copyright 2015. Todos os direitos reservados",
        en_us: "© Copyright 2015. All Rights Reserved",
        es_es: "© Copyright 2015. Todos los derechos reservados",
    },
    checkOurServices: {
        pt_br: "Confira nossos serviços",
        en_us: "Check our services",
        es_es: "Consulte nuestros servicios"
    },
    checkOurServicesDescription: {
        pt_br: "Estamos prontos para lhe atender com serviços de auto padrão, quartos confortáveis e culinária de excelência",
        en_us: "We are ready to serve you with high standard services, comfortable rooms and excellent cuisine",
        es_es: "Estamos prontos para atender sus necesidades con servicios de alto nivel, cómodas habitaciones y excelente cocina."
    },
    restaurant: {
        pt_br: "Restaurante",
        en_us: "Restaurant",
        es_es: "Restaurante"
    },
    restaurantDescription: {
        pt_br: "Venha degustar pratos especiais e exclusivos que lhe proporcionarão uma experiência gastronômica inesquecível, com os melhores ingredientes da alta culinária",
        en_us: "Come and enjoy special and exclusive dishes that will provide you with an unforgettable gastronomic experience, with the best ingredients of haute cuisine",
        es_es: "Venga disfrutar de platos especiales y exclusivos que te proporcionarán una experiencia gastronómica inolvidable, con los mejores ingredientes de la alta cocina."
    },
    recreationArea: {
        pt_br: "Área de Lazer",
        en_us: "Recreation Area",
        es_es: "Área de recreación"
    },
    recreationAreaDescription: {
        pt_br: "Relaxe e divirta-se em nossos ambientes internos destinados ao lazer de nossos clientes",
        en_us: "Relax and have fun in our indoor environments for the leisure of our customers",
        es_es: "Relájate y diviértete en nuestros ambientes interiores para el ocio de nuestros clientes"
    },
    parking: {
        pt_br: "Estacionamento",
        en_us: "Parking",
        es_es: "Estacionamiento"
    },
    parkingDescription: {
        pt_br: "Deixe seu veículo conosco e aproveite ao máximo de sua estadia",
        en_us: "Leave your vehicle with us and make the most of your stay",
        es_es: "Deje su vehículo con nosotros y aproveche al máximo su estadía"
    },
    wifi: {
        pt_br: "Wi-Fi",
        en_us: "Wi-Fi",
        es_es: "Wi-Fi"
    },
    wifiDescription: {
        pt_br: "Disponibilizamos wi-fi em todo os ambientes do hotel para utilização dos hóspedes",
        en_us: "We provide wi-fi throughout the hotel's environments for guests to use",
        es_es: "Ofrecemos wi-fi en todo el entorno del hotel para que los huéspedes lo utilicen"
    },
    aLittleOfUs: {
        pt_br: "Um pouco de nós",
        en_us: "More about us",
        es_es: "Un poco de nosotros"
    },
    aLittleOfUsDescription: {
        pt_br: "Conheça um pouco de nossa estrutura que está esperando por você!",
        en_us: "Discover a little of our structure that is waiting for you!",
        es_es: "Conoce un poco nuestra estructura que te espera"
    },
    comfortAndConvenienceForYou: {
        pt_br: "Conforto e comodidade para você!",
        en_us: "Comfort and convenience for you!",
        es_es: "Comodidad y conveniencia para ti!"
    },
    comfortAndConvenienceForYouDescription: {
        pt_br: "Contamos com 75 apartamentos e 5 suites, sendo que todas acomodações possuem uma infraestrutura completa com ar condicionado split, rede Wi-Fi, TV a cabo LCD, frigobar, cofre e telefone, tudo isso para garantir uma experiência incrível conosco, além de seu conforto e comodidade",
        en_us: "We have 75 apartments and 5 suites, all of which have a complete infrastructure with split air conditioning, Wi-Fi network, LCD cable TV, minibar, safe and telephone, all to ensure an incredible experience with us, in addition to your comfort and convenience",
        es_es: "Contamos con 75 apartamentos y 5 suites, y todos los alojamientos cuentan con una completa infraestructura con aire acondicionado split, Wi-Fi, TV LCD, minibar, caja de seguridad y teléfono, todo para asegurar una experiencia increíble con nosotros, además de su comodidad y conveniencia."
    },
    findOutMoreAboutUs: {
        pt_br: "Descubra mais sobre nós",
        en_us: "Find out more about us",
        es_es: "Descubra más sobre nosotros"
    },
    findOutMoreAboutUsDescription: {
        pt_br: "Inauguramos em 1998, com mais de 20 anos de mercado, estamos entre os melhores hoteis do Brasil. Com atendimento qualificado e agilidade em serviços, somos reconhecidos pela excelência em hospedagem!",
        en_us: "We opened in 1998, with more than 20 years in the market, we are among the best hotels in Brazil. With qualified service and agility in services, we are recognized for excellence in hosting!",
        es_es: "Abrimos en 1998, con más de 20 años en el mercado, y estamos entre los mejores hoteles de Brasil. Con una atención calificada y agilidad en los servicios, somos reconocidos por la excelencia en hospedaje!"
    },
    makeYourEventWithUs: {
        pt_br: "Faça seu evento conosco",
        en_us: "Make your event with us",
        es_es: "Haz tu evento con nosotros"
    },
    makeYourEventWithUsDescription: {
        pt_br: "Contamos com salões para eventos com capacidade de 25, 60 e 100 pessoas",
        en_us: "We have rooms for events with capacity of 25, 60 and 100 people",
        es_es: "Contamos con salones de eventos con capacidad de 25, 60 y 100 personas"
    }
}
