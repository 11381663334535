
export default {
    enterpriseName: "Hotel Metropolitan",
    address: {
        street: {
            pt_br: "Avenida Ernesto Geisel, 5100",
            en_us: "5100, Ernesto Geisel Avenue",
            es_es: "Avenida Ernesto Geisel, 5100"
        },
        city: "Campo Grande, MS",
        country: {
            pt_br: "Brasil",
            en_us: "Brazil",
            es_es: "Brasil"
        },
        googleMapsLink: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3738.1523059966535!2d-54.623787085126345!3d-20.458936686309812!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9486e5d97d2eaa5f%3A0x62cc87d512c10544!2sHotel%20Metropolitan!5e0!3m2!1spt-BR!2sbr!4v1634778232122!5m2!1spt-BR!2sbr"
    },
    contact: {
        email: {
            icon: "fas fa-envelope",
            value: "reservas@hotelmetropolitan.com.br",
            text: {
                pt_br: "E-mail",
                en_us: "E-mail",
                es_es: "E-mail"
            }
        },
        phone: {
            icon: "fas fa-phone-alt",
            value: "(67) 3389-4600",
            text: {
                pt_br: "Telefone",
                en_us: "Phone",
                es_es: "Teléfono"
            }
        },
        onlineReservation: {
            link: "https://reservasonlinebrasil.com.br/motor.asp?passo2=ok&estabelecimento=0185&adt=2&origem=",
            text: {
                pt_br: "Reservas Online",
                en_us: "Online Reservations",
                es_es: "Reservas Online"
            }
        }
    },
    socialLinks: [
        {
            name: "WhatsApp",
            icon: "fab fa-whatsapp",
            link: "https://api.whatsapp.com/send?phone=556733894600",
            toShow: true,
            toShowOnFloatingActionBar: true
        }, {
            name: "Telegram",
            icon: "fab fa-telegram-plane",
            link: "#",
            toShow: false,
            toShowOnFloatingActionBar: false
        }, {
            name: "Instagram",
            icon: "fab fa-instagram",
            link: "https://www.instagram.com/hotel.metropolitan/",
            toShow: true,
            toShowOnFloatingActionBar: false
        }, {
            name: "Facebook",
            icon: "fab fa-facebook-f",
            link: "https://www.facebook.com/hotelmetropolitanoficial",
            toShow: true,
            toShowOnFloatingActionBar: false
        }, {
            name: "Twitter",
            icon: "fab fa-twitter",
            link: "#",
            toShow: false,
            toShowOnFloatingActionBar: false
        }, {
            name: "Skype",
            icon: "fab fa-skype",
            link: "#",
            toShow: false,
            toShowOnFloatingActionBar: false
        }, {
            name: "LinkedIn",
            icon: "fab fa-linkedin",
            link: "#",
            toShow: false,
            toShowOnFloatingActionBar: false
        }, {
            name: "YouTube",
            icon: "fab fa-youtube",
            link: "#",
            toShow: false,
            toShowOnFloatingActionBar: false
        }
    ],
    developer: {
        link: "http://www.lotusdesenvolvimentos.com.br/",
        name: {
            pt_br: "Lotus - Desenvolvimento de Sistemas",
            en_us: "Lotus - Systems Development",
            es_es: "Lotus - Desarrollo de Sistemas"
        },
        text: {
            pt_br: "Desenvolvido por",
            en_us: "Developed by",
            es_es: "Desarrollado por"
        }
    }
}
