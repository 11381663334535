
import './style.css';

import Constants from '../../data/Constants';
import Translations from '../../data/Translations';
import LanguagesTrigger from '../Languages/LanguagesTrigger';
import { useState } from 'react';
import LogoHeader from '../../assets/img/full-logo.png';

const Header = ({currentLanguage}) => {
    const [isNavbarMobile, setIsNavbarMobile] = useState(false);

    return (
        <>
            <section id="topbar" className="d-flex align-items-center">
                <div className="container d-flex justify-content-center justify-content-md-between">
                    <div className="contact-info d-flex align-items-center">
                        <i className={`${Constants.contact.email.icon} d-flex align-items-center`}>
                            <a href={`mailto:${Constants.contact.email.value}`}> {Constants.contact.email.value} </a>
                        </i>
                        
                        <i className={`${Constants.contact.phone.icon} d-flex align-items-center ms-4 d-none d-sm-block`}>
                            <a href={`tel:${Constants.contact.phone.value}`}> {Constants.contact.phone.value} </a>
                        </i>
                    </div>

                    <div className="social-links d-none d-md-flex align-items-center">
                        {
                            Constants.socialLinks
                                .filter(item => item.toShow)
                                .map((item, index) => {
                                    return (
                                        <a key={index} href={item.link} target="_blank" rel="noreferrer" title={item.name} className={item.name.toLowerCase()}>
                                            <i className={item.icon}></i>
                                        </a>
                                    );
                                })
                        }
                    </div>
                </div>
            </section>

            <header id="header" className="d-flex align-items-center">
                <div className="container d-flex align-items-center justify-content-between">

                    {/* <h1 className="logo"><a href="/">{Constants.enterpriseName}</a></h1> */}
                    {/* Uncomment below if you prefer to use an image logo */}
                    <a href="/" className="logo"><img src={LogoHeader} /></a>

                    <nav id="navbar" className={`navbar ${isNavbarMobile ? 'navbar-mobile' : ''}`}>
                        <ul>
                            <li><a className="nav-link scrollto" href="#topbar" onClick={_ => setIsNavbarMobile(false)}> {Translations.home[currentLanguage]} </a></li>
                            <li><a className="nav-link scrollto" href="#services" onClick={_ => setIsNavbarMobile(false)}> {Translations.services[currentLanguage]} </a></li>
                            <li><a className="nav-link scrollto" href="#bedrooms" onClick={_ => setIsNavbarMobile(false)}> {Translations.accommodations[currentLanguage]} </a></li>
                            <li><a className="nav-link scrollto" href="#events" onClick={_ => setIsNavbarMobile(false)}> {Translations.events[currentLanguage]} </a></li>
                            <li><a className="nav-link scrollto" href="#gallery" onClick={_ => setIsNavbarMobile(false)}> {Translations.gallery[currentLanguage]} </a></li>
                            <li><a className="nav-link scrollto " href="#about" onClick={_ => setIsNavbarMobile(false)}> {Translations.about[currentLanguage]} </a></li>
                            <li><a className="nav-link scrollto active onlineReservation small-pulse-animation animation-fast-duration" href={Constants.contact.onlineReservation.link} target="_blank" onClick={_ => setIsNavbarMobile(false)}> {Constants.contact.onlineReservation.text[currentLanguage]} </a></li>
                        </ul>

                        <LanguagesTrigger currentLanguage={currentLanguage} />

                        <i className={`mobile-nav-toggle ${isNavbarMobile ? 'actived fas fa-times' : 'fas fa-bars'}`} onClick={_ => setIsNavbarMobile(!isNavbarMobile)}></i>
                    </nav>

                </div>
            </header>
        </>
    );
}


export default Header;