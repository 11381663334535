import Translations from '../../data/Translations';
import './style.css';

const Services = ({ currentLanguage }) => {
    return (
        <>
            <section id="services" className="featured-services">
                <div className="container" data-aos="fade-up">
                    <div class="section-title">
                        <h2>{Translations.services[currentLanguage]}</h2>
                        <h3>{Translations.checkOurServices[currentLanguage]}</h3>
                        <p>{Translations.checkOurServicesDescription[currentLanguage]}</p>
                    </div>

                    <div className="row row-horizontal-scroll">
                        <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-5 cursor-pointer">
                            <div className="icon-box" data-aos="fade-up" data-aos-delay="100">
                                <div className="icon"><i className="fas fa-bed"></i></div>
                                <h4 className="title"><a> {Translations.accommodations[currentLanguage]} </a></h4>
                                <p className="description"> {Translations.accommodationsDescription[currentLanguage]} </p>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-5 cursor-pointer">
                            <div className="icon-box" data-aos="fade-up" data-aos-delay="100">
                                <div className="icon"><i className="fas fa-utensils"></i></div>
                                <h4 className="title"><a> {Translations.restaurant[currentLanguage]} </a></h4>
                                <p className="description"> {Translations.restaurantDescription[currentLanguage]} </p>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-5 cursor-pointer">
                            <div className="icon-box" data-aos="fade-up" data-aos-delay="200">
                                <div className="icon"><i className="fas fa-glass-cheers"></i></div>
                                <h4 className="title"><a> {Translations.eventHalls[currentLanguage]} </a></h4>
                                <p className="description"> {Translations.eventHallsDescription[currentLanguage]} </p>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-5 cursor-pointer">
                            <div className="icon-box" data-aos="fade-up" data-aos-delay="300">
                                <div className="icon"><i className="far fa-clock"></i></div>
                                <h4 className="title"><a> {Translations._24hourService[currentLanguage]} </a></h4>
                                <p className="description"> {Translations._24hourServiceDescription[currentLanguage]} </p>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-5 cursor-pointer">
                            <div className="icon-box" data-aos="fade-up" data-aos-delay="300">
                                <div className="icon"><i className="fas fa-swimmer"></i></div>
                                <h4 className="title"><a> {Translations.recreationArea[currentLanguage]} </a></h4>
                                <p className="description"> {Translations.recreationAreaDescription[currentLanguage]} </p>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-5 cursor-pointer">
                            <div className="icon-box" data-aos="fade-up" data-aos-delay="300">
                                <div className="icon"><i className="fas fa-car"></i></div>
                                <h4 className="title"><a> {Translations.parking[currentLanguage]} </a></h4>
                                <p className="description"> {Translations.parkingDescription[currentLanguage]} </p>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-5 cursor-pointer">
                            <div className="icon-box" data-aos="fade-up" data-aos-delay="300">
                                <div className="icon"><i className="fas fa-wifi"></i></div>
                                <h4 className="title"><a> {Translations.wifi[currentLanguage]} </a></h4>
                                <p className="description"> {Translations.wifiDescription[currentLanguage]} </p>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-5 cursor-pointer">
                            <div className="icon-box" data-aos="fade-up" data-aos-delay="300">
                                <div className="icon"><i className="fab fa-accessible-icon"></i></div>
                                <h4 className="title"><a> {Translations.accessibility[currentLanguage]} </a></h4>
                                <p className="description"> {Translations.accessibilityDescription[currentLanguage]} </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Services;